import * as React from 'react'
import { Link } from 'gatsby'
import Footer from './Footer'

import Logo from '../assets/logo.svg'
import { DropDown } from './DropDown'

import inIcon from '../images/instagram.svg'
import { useLocation } from '@reach/router'
import fbIcon from '../images/facebook.svg'
import { DRAWER_PAGE, KITCHENS_PAGE, PAGES, WARDROBE_PAGE } from '../pages'
import { NAV_DESELECTED_COLOR } from '../constant'

import MobileNavigation from './navigation/MobileNavigation'
import Button from './Button'

const subItems = [KITCHENS_PAGE, WARDROBE_PAGE, DRAWER_PAGE]

//
// notice that the location is passed manually from the page where the Layout is added
//
const Layout = (props) => {
  const location = useLocation()
  const children = props.children

  const dropSelected =
    subItems.filter((page) => {
      return page.to === location.pathname
    }).length > 0

  const iconSize = 46

  return (
    <div className="d-flex flex-column flex-grow-0 " style={{ minHeight: '100vh' }}>
      <MobileNavigation></MobileNavigation>

      {/*
      --------------------------
      HIDE ON MOBILE
      --------------------------
      */}
      <div
        className="sticky-top container-fluid h-100 d-none d-md-block "
        style={{ zIndex: 50, background: '#f8f8f8' }}
      >
        <div className="row h-100" style={{}}>
          <div className="col-3 ">
            <Link to="/">
              <Logo className="my-3 ms-3" style={{ height: '1.8rem' }}></Logo>
            </Link>
          </div>

          <div className="col-6 d-flex justify-content-between ">
            <div className="d-none d-md-flex justify-content-center w-100 ">
              {PAGES.map((page, index) => {
                if (page.sub) {
                  return (
                    <DropDown
                      key={'submenu'}
                      pathname={location.pathname}
                      selected={dropSelected}
                      items={subItems}
                    ></DropDown>
                  )
                }

                return (
                  <Link
                    key={index}
                    to={page.to}
                    className="mx-4 mt-3 pt-1 text-decoration-none"
                    style={{ color: NAV_DESELECTED_COLOR }}
                    activeStyle={{ color: 'black', borderBottom: '2px solid black', fontWeight: 600 }}
                  >
                    <p className="p-nav">{page.title}</p>
                  </Link>
                )
              })}
            </div>
          </div>

          <div className="col-3 d-flex justify-content-end align-items-center">
            <Link to="/kontakt" className="mt-0 me-1 text-decoration-none  ">
              <Button className="py-1 " title="Bestil nu"></Button>
            </Link>
            <a
              className=" "
              href="https://www.facebook.com/HackkDenmark/"
              target="_blank"
              rel="noreferrer"
              aria-label="visit facebook page"
            >
              <img src={fbIcon} style={{ width: iconSize, height: iconSize }} alt="" />
            </a>
            <a
              className=""
              href="https://www.instagram.com/h.a.c.k.k/"
              target="_blank"
              rel="noreferrer"
              aria-label="visit instagram page"
            >
              {/*<InstaIcon></InstaIcon>*/}
              <img src={inIcon} style={{ width: iconSize, height: iconSize }} alt="" />
            </a>
          </div>
        </div>
      </div>
      {children}
      <Footer></Footer>
    </div>
  )
}

export default Layout
