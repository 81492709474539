export const BASE_URL = process.env.GATSBY_BASE_URL
export const DEBUG = process.env.GATSBY_DEBUG

export const PRIMARY_COLOR = '#121533'
export const NAV_DESELECTED_COLOR = '#8c8c8c'
export const PLACEHOLDER_COLOR = '#cccccc'
export const PLACEHOLDER_COLOR_LIGHT = '#eceff1'
export const BACKGROUND_COLOR = '#f8f8f8'
export const BACKGROUND_FOOTER = '#000000'

export const DELIVERY_TIME = '7-14 uger'

export const CONTACT = {
  //email: 'hello@hackk.dk',
  address: 'Enghavevej 12A',
  zip: '1620',
  city: 'København V',
  phone: '+45 9399 2398',
  email: 'kontakt@hackk.dk',
  googleLink:
    'https://www.google.com/maps/place/Enghavevej+12a,+1620+K%C3%B8benhavn/@55.670306,12.5459064,3a,75y,283.59h,73.83t/data=!3m6!1e1!3m4!1sLfYAS_EWsoyZBDHETFtgXQ!2e0!7i16384!8i8192!4m5!3m4!1s0x4652539f007b177d:0x116f76bb1bbff490!8m2!3d55.6707097!4d12.5455173',
}

export const OPENINGS_HOURS_CPH = {
  MONDAY: 'Mandag Kl. 13:00 - 18:00',
  TUESDAY: 'Tirsdag kl. 13:00 – 18:00',
  WEDNESDAY: 'Onsdag  kl. 10 – 17',
  THURSDAY: 'Torsdag kl. 10 – 17',
  FRIDAY: 'Fredag ',
  SATURDAY: 'Lørdag kl. 10 - 14 ',
  SUNDAY: 'Søndag og helligdage',
}

export const CONTACT_AARHUS = {
  address: 'Vesterbrogade 16',
  zip: '8000',
  city: 'Aarhus C',
  phone: '+45 9399 2398',
  googleLink:
    'https://www.google.com/maps/place/Vesterbrogade+16,+8000+Aarhus/@56.1579444,10.1967581,3a,75y,321.81h,83.84t/data=!3m6!1e1!3m4!1snoHGNHF0WR8RA3tNJEAwhQ!2e0!7i16384!8i8192!4m5!3m4!1s0x464c3feb617f4d37:0xc764c9b40695e42e!8m2!3d56.1580074!4d10.1967504',
}

export const LINOLEUM_TEXT =
  'Med linoleum får du en blød og solid overflade, der er rar at røre ved og giver god akustik. Det er et ' +
  'supermat organisk materiale, som skjuler fedtfingre og er ekstremt holdbart.'

export const VENEER_TEXT =
  'Med natur-finish får du et helt klassisk snedkerkøkken. Vi udvælger nøje årenes forløb i det ' +
  'håndolierede finér, hvor over- og underskab altid er i naturlig forlængelse af hinanden. Når kun ' +
  'komplet snedkerluksus er godt nok. Vælg mellem eg, røget eg og valnød:'

export const PAINTED_TEXT =
  'Skal det være malet finish med over 2.200 farver at vælge imellem? Måske linoleum, der er blødt at røre ' +
  'ved og samtidig tåler hårdhændet behandling? Eller håndolieret naturfinér, hvor træets årer får lov til ' +
  'at stråle?'

const painted_pax = 'PAX malet'
const pax_oak = 'PAX eg finér'
const pax_linoleum_oak = 'PAX linoleum med eg'
const pax_linoleum_wallnut = 'PAX linoleum med valnød'
const pax_linoleum_smooked_oak = 'PAX linoleum med røget eg'

const painted_metod = 'Malet'
const linoleum_oak = 'Linoleum med eg'
const linoleum_smooked_oak = 'Linoleum med røget eg'
const oak_veneer = 'Eg finér'
const smooked_oak_veneer = 'Røget eg finér'
const linoleum_wallnut = 'Linoleum med valnød'
const wallnut_veneer = 'Valnød finér'

export const titleLookUp = {
  painted_method: painted_metod,
  linoleum_oak: linoleum_oak,
  linoleum_smooked_oak: linoleum_smooked_oak,
  linoleum_wallnut: linoleum_wallnut,
  oak_veneer: oak_veneer,
  smooked_oak_veneer: smooked_oak_veneer,
  wallnut_veneer: wallnut_veneer,

  oak_laminat: 'Laminat med eg',
  wallnut_laminat: 'Laminat med valnød',
  smooked_oak_laminat: 'Laminat med røget eg',

  painted_pax: painted_pax,
  pax_oak: pax_oak,
  pax_linoleum_oak: pax_linoleum_oak,
  pax_linoleum_wallnut: pax_linoleum_wallnut,
  pax_linoleum_smooked_oak: pax_linoleum_smooked_oak,
}

export const colors = [
  { title: 'Anthracite', color: '#737270' },
  { title: 'Shadow', color: '#a0abad' },
  { title: 'Grey', color: '#bcbbba' },
  { title: 'Fawn', color: '#a5957b' },
  { title: 'Peanut', color: '#ba9b71' },
  { title: 'Latte', color: '#bca38c' },

  { title: 'Linen', color: '#e0dfdd' },
  { title: 'Bone', color: '#d5d1cb' },
  { title: 'Dove', color: '#d0cecf' },
  { title: 'Elephant', color: '#b1b0ae' },
  { title: 'Alabaster', color: '#efede7' },
  { title: 'Tan', color: '#e7d6c8' },

  { title: 'Egg-Shell', color: '#f7edd6' },
  { title: 'Lace', color: '#f7edd6' },
  { title: 'Frost', color: '#dbdee0' },
  { title: 'Chiffon', color: '#e8ede9' },
  { title: 'Pearl', color: '#eaebe9' },
  { title: 'Cotton', color: '#eeeaeb' },

  { title: 'Kahki', color: '#d3d2c7' },
  { title: 'Clay', color: '#d3d2c6' },
  { title: 'Mint', color: '#dae1d7' },
  { title: 'Pistachio', color: '#dedecc' },
  { title: 'Sage', color: '#849687' },
  { title: 'Hunting', color: '#3a3c33' },

  { title: 'Petrol', color: '#4f5559' },
  { title: 'Slate', color: '#56656d' },
  { title: 'Cloud', color: '#bfc1c6' },
  { title: 'Stone', color: '#cbd1d1' },
  { title: 'Silverlake', color: '#bac2c3' },
  { title: 'Indigo', color: '#658395' },

  { title: 'Dusty Red', color: '#bc907b' },
  { title: 'Daisy', color: '#dbc1b8' },
  { title: 'Rose', color: '#a37673' },
  { title: 'Taupe', color: '#9e8983' },
  { title: 'Mulberry', color: '#5e2629' },
  { title: 'Fossil', color: '#c9bcba' },
]
export const colorsLinoleum = [
  { title: 'Charcoal', color: '#232124' },
  { title: 'Nero', color: '#1E1F21' },
  { title: 'Iron', color: '#54504C' },
  { title: 'Mauve', color: '#5E534F' },
  { title: 'Pebble', color: '#B3AFA4' },
  { title: 'Burgundy', color: '#4B2123' },
  { title: 'Conifer', color: '#0A3025' },
  { title: 'Smokey Blue', color: '#445461' },
  { title: 'Olive', color: '#838772' },
  { title: 'Pistachio', color: '#C2CCB4' },
  { title: 'Powder', color: '#DCC8BD' },
  { title: 'Aquavert', color: '#87B8BA' },
  { title: 'Spring Green', color: '#CACE69' },
  { title: 'Orange Blast', color: '#EB5D2D' },
  { title: 'Midnight Blue', color: '#2E5C8F' },
  { title: 'Salsa', color: '#9B2521' },
  { title: 'Pearl', color: '#F4E7C5' },
  { title: 'Mushromm', color: '#D6D2C6' },
  { title: 'Vapour', color: '#929793' },
  { title: 'Pewter', color: '#4C5456' },
]

export const colorsLaminat = [
  { title: 'Maltese Mist', color: '#232124' },
  { title: 'Casa Blanca', color: '#1E1F21' },
  { title: 'High Low ', color: '#54504C' },
  { title: 'Capital Starlit', color: '#5E534F' },
  { title: 'Boxcar Blonde', color: '#B3AFA4' },
  { title: 'Silver Lake', color: '#4B2123' },
  { title: 'Smoke Stack', color: '#0A3025' },
  { title: 'Trench Coat', color: '#445461' },
  { title: 'Gaslit Alley', color: '#838772' },
  { title: 'Olive Detour', color: '#C2CCB4' },
  { title: 'Midnight Dash', color: '#DCC8BD' },
  { title: 'After Dark', color: '#87B8BA' },
]
