/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
//import React from 'react'

import * as ReactDOM from 'react-dom/client'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'

import React, { useEffect, useRef } from 'react'
import Layout from './src/components/layout'
import { animated, useTransition } from '@react-spring/web'
import './src/styles/styles.scss'

/*if (process.env.NODE_ENV !== 'production') {
  import('./src/styles/size-debug.scss').then((something) => {})
}*/

// You can delete this file if you're not using it
export const wrapPageElement = ({ element, props }) => {
  const location = { props }

  // return element
  return (
    <Layout>
      <Base children={element} location={location}>
        {element}
      </Base>
    </Layout>
  )
}

const Base = ({ location, children }) => {
  const offSet = 70
  const ref = useRef()

  const [transitions, api] = useTransition(children, (item) => ({
    from: {
      opacity: ref.current ? 0 : 1,
      transform: ref.current ? `translate3d(0px, ${-offSet}px,0)` : `translate3d(0px, 0px,0)`,
    },
    enter: { opacity: 1, transform: `translate3d(0px, ${0}px,0)` },
    leave: { opacity: 0, transform: `translate3d(0px, ${offSet}px,0)` },
    config: {
      // ease: easings.easeInExpo(10, 'end'),
      //duration: 200,
      tension: 210,
      friction: 30,
    },

    exitBeforeEnter: true,
  }))

  useEffect(() => {
    if (ref.current) {
      api.start()
    }
    ref.current = true

    //api.start()
  }, [location])

  return transitions((style, item) => <animated.div style={style}>{item}</animated.div>)
}

/*
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
*/
