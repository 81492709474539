/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-08-2020.
 */
import React from 'react'

import Button from './Button'

//const on = '#e2e2e2'
//const off = '#1d1d1d'
const ButtonWhite = ({
  title = 'title',
  className = '',
  style = {},

  onClick = null,
}) => {
  return <Button title={title} style={style} on="#C4C4C4" off="#FFFFFF" txtOn="#000000" txtOff="#000000"></Button>
}
export default ButtonWhite
