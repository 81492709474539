import React from 'react'
import Logo from '../assets/logo.svg'
import { BACKGROUND_FOOTER, CONTACT, OPENINGS_HOURS_CPH } from '../constant'
import FacebookIcon from '../assets/facebook.svg'
import InstagramIcon from '../assets/instagram.svg'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { CONTACT_PAGE, HOW_PAGE, KITCHENS_MODEL_PAGE, SHOWROOM_PAGE, WARDROBE_MODEL_PAGE } from '../pages'
import FooterPillButton from './FooterPillButton'
import ButtonWhite from './ButtonWhite'

const Footer = (props) => {
  const result = useStaticQuery(
    graphql`
      query FooterProducts {
        metod: allMetodProductsJson {
          edges {
            node {
              id
              name
              type
              text
              image {
                id
              }
            }
          }
        }

        pax: allPaxProductsJson {
          edges {
            node {
              id
              name
              type
              text
              image {
                id
              }
            }
          }
        }
      }
    `
  )

  return (
    <footer className="container-fluid mt-auto" style={{ background: BACKGROUND_FOOTER }}>
      <div className="container">
        <div className="row" style={{}}>
          <div className="col-12 mb-6 mt-6 ">
            <h2 className="text-center text-white">Quick links</h2>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-11 mx-auto">
            <FooterPillButton to={SHOWROOM_PAGE.to} label="Se Åbningstider"></FooterPillButton>
            <FooterPillButton to={CONTACT_PAGE.to} label="Kontakt os"></FooterPillButton>
            <FooterPillButton to={HOW_PAGE.to} label="Se ofte stillede spørgsmål"></FooterPillButton>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row" style={{}}>
          <div className="col-12 mt-4">
            <hr className="text-white opacity-25" />
          </div>
        </div>
        <div className="row" style={{}}>
          <ProductList title="Køkken" arr={result.metod} getLinkFunc={KITCHENS_MODEL_PAGE.gotoPage}></ProductList>
          <ProductList title="Garderobe" arr={result.pax} getLinkFunc={WARDROBE_MODEL_PAGE.gotoPage}></ProductList>

          <div className="col-6 col-md-3 mt-4">
            <div className="row" style={{}}>
              <div className="col-12">
                <p className="text-white mb-3">Tilbehør</p>
              </div>
              <div className="col-12 mb-2">
                <Link to="/skuffer" className="mt-2 text-white text-decoration-none">
                  <p className="text-white opacity-50">Skuffer</p>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-12 mt-4">
            <div className="row" style={{}}>
              <div className="col-12">
                <p className="text-white mb-3">Hackk</p>
              </div>
              <div className="col-12 mb-2">
                <Link to="/kontakt" className="mt-2 text-white text-decoration-none">
                  <p className="text-white opacity-50">Kontakt</p>
                </Link>
              </div>
              <div className="col-12 mb-2 overflow-hidden">
                <Link to="/term-conditions" className="mt-2 text-white text-decoration-none">
                  <p className="text-white opacity-50 " style={{ wordBreak: 'break-word' }}>
                    Handels- og forretningsbestingelser
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12 mt-4">
            <hr className="text-white opacity-25" />
          </div>
        </div>
      </div>
      <div className="container-fluid content-container mb-7 mt-6 ">
        <div className="row" style={{}}>
          <div className="col-12 mb-0">
            {/*<img src={logo} style={{ width: '8rem' }} alt="" />*/}
            <Logo style={{ fill: 'white', width: '8rem' }}></Logo>
          </div>
        </div>

        <div className="row" style={{}}>
          <div className="col-12 col-md-6 mt-5">
            <div className="row" style={{}}>
              <div className="col-6 text-white">
                {/*<img className="mb-5" src={mapIcon} alt="" />*/}

                {/*<MapIcon style={{ fill: 'white' }}></MapIcon>*/}
                <a className="" href={CONTACT.googleLink} target="_blank" rel="noopener noreferrer">
                  <p className="mb-1 p-large-bold text-white ">
                    <u>
                      {CONTACT.address}, {CONTACT.zip},
                    </u>
                  </p>
                  <p className="mb-3 p-large-bold text-white">{CONTACT.city}</p>
                </a>
                <a href="mailto:kontakt@hackk.dk" target="_blank" rel="noopener noreferrer">
                  <p className="mb-1 p-large-bold text-white ">
                    <u>kontakt@hackk.dk</u>
                  </p>
                </a>
                <p className=" text-white opacity-75">CVR: 44 61 99 62 </p>
              </div>
            </div>
          </div>
          {/* -------------------------

          OPENING HOURS

          ------------------------- */}
          <div className="col-12 col-md-3 mt-5 text-white">
            <p className="mb-3 fw-bold ">Åbningstider</p>
            <p className="mb-0 p-small ">{OPENINGS_HOURS_CPH.MONDAY} </p>
            <p className="mb-0 p-small">{OPENINGS_HOURS_CPH.TUESDAY}</p>

            <p className="faded mb-0 p-small">{OPENINGS_HOURS_CPH.WEDNESDAY}</p>
            <p className="faded mb-0 p-small ">{OPENINGS_HOURS_CPH.THURSDAY}</p>

            <p className="mb-0 p-small">
              {OPENINGS_HOURS_CPH.FRIDAY}
              <span className="ms-1" style={{ opacity: 0.6 }}>
                (åbent efter aftale)
              </span>{' '}
            </p>
            <p className="faded mb-0 p-small">
              {OPENINGS_HOURS_CPH.SATURDAY}
              <span className="ms-1" style={{ opacity: 0.6 }}>
                (lige uger)
              </span>
            </p>
            <p className="mb-0 p-small">
              {OPENINGS_HOURS_CPH.SUNDAY}{' '}
              <span className="ms-1 text-white" style={{ opacity: 0.6 }}>
                (lukket)
              </span>
            </p>
          </div>
          <div className="col-12 col-md-3">
            <div className="d-flex justify-content-end align-items-end h-100 ">
              <a
                href="https://www.instagram.com/h.a.c.k.k/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit instagram page"
              >
                <InstagramIcon style={{ width: 60, height: 60, fill: 'white' }}></InstagramIcon>
              </a>
              <a
                href="https://www.facebook.com/HackkDenmark/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit facebook page"
              >
                <FacebookIcon style={{ width: 60, height: 60, fill: 'white' }}></FacebookIcon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const ProductList = ({ arr, title, getLinkFunc }) => {
  return (
    <div className="col-6 col-md-3 mt-4">
      <div className="row" style={{}}>
        <div className="col-12">
          <p className="text-white mb-3">{title}</p>
        </div>

        {arr.edges.map((item, idx) => {
          return (
            <div key={idx} className="col-12 mb-2">
              <Link to={getLinkFunc(item.node.name)}>
                <p className="text-white opacity-50">{item.node.name}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Footer
